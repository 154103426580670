export const getCurrentlySelectedEvent = state => {
  const { eventId } = state.userSelection.toJS()
  return state.entities.events.get(eventId).toJS()
}

export const isMultiInventoryEnabled = (state, event) => {
  const currentlySelectedEvent = event || getCurrentlySelectedEvent(state)
  return (
    state.featureFlags.isMultiInventoryTypeEnabled &&
    currentlySelectedEvent.isMultiInventory &&
    !currentlySelectedEvent.hasInteractiveFloorplan
  )
}

import _ from 'lodash'
import { isMultiInventoryEnabled } from '../selectors/eventSelector'
import { calculatePricingDetails } from '../selectors/inventoryCartSelector'
import Analytics from '../services/AnalyticsServices'
import scrollTo from '../utils/pageScroll'
import {
  REVERT_STAGE,
  STEP_FORWARD_MONTH,
  STEP_BACK_MONTH,
  SCROLL_BODY,
  TOP_BODY,
  SELECT_EVENT,
  RESIZE_BODY,
  INCREMENT_ITEM,
  DECREMENT_ITEM,
  TOGGLE_INVENTORY_ITEM,
  SELECT_INVENTORY_ITEM,
  TOGGLE_BOOKING_AGREEMENT,
  TOGGLE_VENUE_GROUP_MARKETING_OPT_IN,
  TOGGLE_VENUE_SPECIFIC_MARKETING_OPT_IN,
  TOGGLE_VENUE_SMS_MARKETING_OPT_IN,
  TOGGLE_TAILORED_COMMUNICATION_OPT_IN,
  TOGGLE_ABOVE_AGE_CONSENT,
  TOGGLE_SPECIAL_ATTENTION_MESSAGE,
  TOGGLE_VENUE_GROUP_MARKETING_OPT_IN_DISPLAY,
  TOGGLE_VENUE_SPECIFIC_MARKETING_OPT_IN_DISPLAY,
  TOGGLE_VENUE_SMS_MARKETING_OPT_IN_DISPLAY,
  TOGGLE_RESERVATION_SMS_OPT_IN_POLICY_DISPLAY,
  TOGGLE_TAILORED_COMMUNICATION_OPT_IN_DISPLAY,
  TOGGLE_RESERVATION_SMS_OPT_IN,
  TOGGLE_PROMO_CODE_ERROR_DISPLAY,
  UPDATE_TIP_AMOUNT,
  TOGGLE_BOOKING_POLICY_DISPLAY,
  BEGIN_SCROLL_ANIMATION,
  END_SCROLL_ANIMATION,
  TOGGLE_IMAGE_GALLERY,
  STEP_FORWARD_IMAGE,
  STEP_BACK_IMAGE,
  DISMISS_SPINNER,
  HOVER_DATE,
  HIGHLIGHT_DATE,
  UNHIGHLIGHT_DATE,
  CLEAR_ERROR_SCROLLING,
  SKIP_LOGIN,
  DISPLAY_MODAL,
  UPDATE_PRICES,
  REMOVE_PROMO_CODE,
} from './ActionTypes'

export const revertStage = index => (dispatch, getState) => {
  const state = getState()
  const isMultiInventoryTypeEnabled = isMultiInventoryEnabled(state)
  dispatch({
    type: REVERT_STAGE,
    index,
    isMultiInventoryTypeEnabled,
  })

  if (state.entities.promoCode) {
    dispatch({ type: REMOVE_PROMO_CODE })
    const pricingDetails = calculatePricingDetails(state)
    dispatch({
      type: UPDATE_PRICES,
      pricingDetails,
    })
  }
}

export const stepForwardMonth = () => ({ type: STEP_FORWARD_MONTH })

export const stepBackMonth = () => ({ type: STEP_BACK_MONTH })

export const scrollBody = (offset, bodyHeight, listHeight) => ({
  type: SCROLL_BODY,
  offset,
  bodyHeight,
  listHeight,
})

export const topBody = () => ({ type: TOP_BODY })

export const selectEvent = (date, eventId) => (dispatch, getState) => {
  const state = getState()
  const currentlySelectedEventId = state.userSelection.get('eventId')
  const selectedEvent = state.entities.events.get(eventId).toJS()
  const isMultiInventoryTypeEnabled = isMultiInventoryEnabled(state, selectedEvent)
  const currentlySelectedDate = state.userSelection.get('date')
  dispatch({
    type: SELECT_EVENT,
    date,
    eventId,
    currentlySelectedEventId,
    currentlySelectedDate,
    isMultiInventoryTypeEnabled,
    data: { inventory: state.entities.inventory.toJS() },
  })
}

export const resizeBody = (width, height) => ({ type: RESIZE_BODY, width, height })

export const incrementItem = (date, eventId, inventoryId) => (dispatch, getState) => {
  const state = getState()
  const isMultiInventoryTypeEnabled = isMultiInventoryEnabled(state)
  dispatch({
    type: INCREMENT_ITEM,
    date,
    eventId,
    inventoryId,
    isMultiInventoryTypeEnabled,
  })
}

export const decrementItem = (date, eventId, inventoryId) => ({
  type: DECREMENT_ITEM,
  date,
  eventId,
  inventoryId,
})

export const toggleInventoryItem = (date, eventId, inventoryId) => ({
  type: TOGGLE_INVENTORY_ITEM,
  date,
  eventId,
  inventoryId,
})

export const selectInventoryItems = inventoryItems => (dispatch, getState) => {
  const state = getState()
  const hasClientToken = !_.isEmpty(state.clientInfo)
  const firstName = hasClientToken ? state.clientInfo.firstName : state.formFields.get('firstName')
  const lastName = hasClientToken ? state.clientInfo.lastName : state.formFields.get('lastName')
  const email = hasClientToken ? state.clientInfo.email : state.formFields.get('email')
  const phoneNumber = hasClientToken ? state.clientInfo.phoneNumberLocalized.toString() : ''
  const phoneNumberLocale = hasClientToken ? state.clientInfo.phoneNumberLocale : state.formFields.get('phoneNumberLocale')
  const dialCode = hasClientToken ? state.clientInfo.dialcode : state.formFields.get('dialCode')
  const isSocialMediaLoginEnabled = state.widgetSettings.enableSocialMediaLogin
  const pricingDetails = calculatePricingDetails(state)

  inventoryItems.forEach(({ inventoryId, availabilityId }) => {
    Analytics.selectInventory(inventoryId, availabilityId)
  })

  dispatch({
    type: SELECT_INVENTORY_ITEM,
    inventoryItems,
    hasClientToken,
    firstName,
    lastName,
    email,
    phoneNumber,
    phoneNumberLocale,
    dialCode,
    isSocialMediaLoginEnabled,
    pricingDetails,
  })
}

export const toggleBookingAgreement = () => ({ type: TOGGLE_BOOKING_AGREEMENT })

export const dismissModal = () => ({ type: DISPLAY_MODAL })

export const toggleModalDisplay = modalToDisplay => ({ type: DISPLAY_MODAL, modal: modalToDisplay })

export const toggleVenueGroupMarketingOptIn = () => ({
  type: TOGGLE_VENUE_GROUP_MARKETING_OPT_IN,
})

export const toggleVenueSpecificMarketingOptIn = () => ({
  type: TOGGLE_VENUE_SPECIFIC_MARKETING_OPT_IN,
})

export const toggleVenueSmsMarketingOptIn = () => ({
  type: TOGGLE_VENUE_SMS_MARKETING_OPT_IN,
})

export const toggleTailoredCommunicationOptIn = () => ({
  type: TOGGLE_TAILORED_COMMUNICATION_OPT_IN,
})

export const toggleReservationSmsOptIn = () => ({
  type: TOGGLE_RESERVATION_SMS_OPT_IN,
})

export const toggleAboveAgeConsent = () => ({
  type: TOGGLE_ABOVE_AGE_CONSENT,
})

export const toggleSpecialAttentionMessage = () => ({
  type: TOGGLE_SPECIAL_ATTENTION_MESSAGE,
})

export const toggleBookingPolicyDisplay = () => ({
  type: TOGGLE_BOOKING_POLICY_DISPLAY,
})
export const toggleVenueGroupMarketingOptInPolicyDisplay = () => ({
  type: TOGGLE_VENUE_GROUP_MARKETING_OPT_IN_DISPLAY,
})

export const toggleVenueSpecificMarketingOptInPolicyDisplay = () => ({
  type: TOGGLE_VENUE_SPECIFIC_MARKETING_OPT_IN_DISPLAY,
})

export const toggleVenueSmsMarketingOptInPolicyDisplay = () => ({
  type: TOGGLE_VENUE_SMS_MARKETING_OPT_IN_DISPLAY,
})

export const toggleReservationSmsOptInPolicyDisplay = () => ({
  type: TOGGLE_RESERVATION_SMS_OPT_IN_POLICY_DISPLAY,
})

export const toggleTailoredCommunicationOptInDisplay = () => ({
  type: TOGGLE_TAILORED_COMMUNICATION_OPT_IN_DISPLAY,
})

export const togglePromoCodeErrorDisplay = () => ({
  type: TOGGLE_PROMO_CODE_ERROR_DISPLAY,
})

export const updateCustomTipAmount = e => (dispatch, getState) => {
  dispatch({
    type: UPDATE_TIP_AMOUNT,
    value: e.target.value,
  })

  const state = getState()
  const pricingDetails = calculatePricingDetails(state)
  dispatch({
    type: UPDATE_PRICES,
    pricingDetails,
  })
}

export const beginScrollAnimation = () => ({ type: BEGIN_SCROLL_ANIMATION })

export const endScrollAnimation = () => ({ type: END_SCROLL_ANIMATION })

export const initScrollAnimation = targetId => dispatch => {
  dispatch(beginScrollAnimation())
  const element = window.document.getElementById(targetId)
  const doScroll = () => {
    scrollTo(targetId, -134, () => {
      dispatch(endScrollAnimation())
      for (let i = 0; i < element.children.length; i += 1) {
        if (_.get(element.children[i], ['attributes', 'role', 'value']) === 'button') {
          element.children[i].focus()
          break
        }
      }
    })
  }
  if (_.isNil(element)) {
    window.setTimeout(doScroll, 100)
  } else {
    doScroll()
  }
}

export const initErrorScrollAnimation = targetId => dispatch => {
  dispatch(beginScrollAnimation())
  scrollTo(targetId, -75, () => {
    dispatch(endScrollAnimation())
  })
}

export const toggleImageGallery = () => ({ type: TOGGLE_IMAGE_GALLERY })

export const stepForwardImage = imgListLength => ({
  type: STEP_FORWARD_IMAGE,
  imgListLength,
})

export const stepBackImage = () => ({ type: STEP_BACK_IMAGE })

export const dismissSpinner = () => ({ type: DISMISS_SPINNER })

export const hoverDate = date => ({ type: HOVER_DATE, date })

export const highlightDate = date => ({ type: HIGHLIGHT_DATE, date })

export const unhighlightDate = date => ({ type: UNHIGHLIGHT_DATE, date })

export const selectDate = date => dispatch => {
  Analytics.selectDate(date)
  dispatch(highlightDate(date))
  setTimeout(() => {
    dispatch(unhighlightDate(date))
  }, 4000)
}

export const clearErrorScrolling = () => ({ type: CLEAR_ERROR_SCROLLING })

export const skipLogin = () => dispatch => {
  Analytics.skipLogin()
  dispatch({ type: SKIP_LOGIN })
}

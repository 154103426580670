import {
  GET_EVENTS_SUCCESS,
  SELECT_EVENT,
  INCREMENT_ITEM,
  DECREMENT_ITEM,
  SELECT_INVENTORY_ITEM,
  UPDATE_PRICES,
  REVERT_STAGE,
} from '../actions/ActionTypes'
import { GUESTLIST_FREE_TYPE, GUESTLIST_TYPE, type RESERVATION_TYPE, REQUEST_TYPE } from './inventoryReducer'

type InventoryType = typeof GUESTLIST_TYPE | typeof GUESTLIST_FREE_TYPE | typeof REQUEST_TYPE | typeof RESERVATION_TYPE

export interface InventoryCartState {
  cart: {
    inventoryId: string
    inventoryType: InventoryType
    quantity: number
    availabilityId?: string
    basePrice?: number
    discount?: number
    tax?: number
    taxPercent?: number
    chargeTax?: boolean
    serviceCharge?: number
    serviceChargePercent?: number
    additionalFeeAmount?: number
    additionalFeeTaxAmount?: number
    additionalFeeTaxPercent?: number
    tip?: number
    subTotal?: number
    total?: number
  }[]
}

interface Inventory {
  inventoryId: string
  inventoryType: InventoryType
  minQuantity: number
}

interface InventoryCartStateAction {
  type: string
  date?: string
  eventId?: string
  currentlySelectedDate?: string
  currentlySelectedEventId?: string
  inventoryId?: string
  inventoryItems?: {
    inventoryId: string
    availabilityId: string
  }[]
  data?: {
    inventory: { [key: string]: Inventory }
  }
  pricingDetails?: {
    basePrice: string
    tax: number
    taxPercent?: number
    chargeTax?: boolean
    serviceCharge: number
    serviceChargePercent: number
    additionalFeeAmount: number
    additionalFeeTaxAmount: number
    additionalFeeTaxPercent: number
    tip: number
    subTotal: number
    total: number
  }
  isMultiInventoryTypeEnabled?: boolean
  index?: number
}

const inventoryCart = (state: InventoryCartState = { cart: [] }, action: InventoryCartStateAction) => {
  switch (action.type) {
    case GET_EVENTS_SUCCESS: {
      const { data } = action

      if (!data?.inventory) {
        return state
      }

      const cart: InventoryCartState['cart'] = state.cart.slice()
      const inventoryIdsInCart = cart.map(inventory => inventory.inventoryId)
      Object.values(data.inventory).forEach(({ inventoryId, inventoryType }) => {
        if (!inventoryIdsInCart.includes(inventoryId)) {
          cart.push({ inventoryId, inventoryType, quantity: 0 })
        }
      })

      return {
        ...state,
        cart,
      }
    }
    case SELECT_EVENT: {
      const { currentlySelectedDate, currentlySelectedEventId, date, eventId, isMultiInventoryTypeEnabled, data } = action

      if (currentlySelectedDate === date && currentlySelectedEventId === eventId) {
        return state
      }

      if (isMultiInventoryTypeEnabled) {
        return {
          ...state,
          cart: state.cart.map(item => ({ ...item, quantity: 0 })),
        }
      }

      return {
        ...state,
        cart: state.cart.map(item => {
          const minQuantity = data?.inventory[item.inventoryId]?.minQuantity || 1
          return { ...item, quantity: minQuantity }
        }),
      }
    }
    case REVERT_STAGE: {
      const { isMultiInventoryTypeEnabled } = action

      return {
        ...state,
        cart: state.cart.map(item => {
          let { quantity } = item
          if (isMultiInventoryTypeEnabled && item.inventoryType === REQUEST_TYPE) {
            // Reset the request type inventory to 0 so that the inventory list page is re-enabled.
            quantity = 0
          }
          return {
            ...item,
            quantity,
            availabilityId: undefined,
          }
        }),
      }
    }
    case INCREMENT_ITEM: {
      const { inventoryId, isMultiInventoryTypeEnabled } = action
      if (!isMultiInventoryTypeEnabled) {
        return {
          ...state,
          cart: state.cart.map(item => (item.inventoryId === inventoryId ? { ...item, quantity: item.quantity + 1 } : item)),
        }
      }

      const inventoryType = state.cart.find(item => item.inventoryId === inventoryId)?.inventoryType
      const cart = state.cart.map(item => {
        if (item.inventoryId === inventoryId) {
          return { ...item, quantity: item.quantity + 1 }
        } else if (!isInSameInventoryGroup(item.inventoryType, inventoryType)) {
          return { ...item, quantity: 0 }
        }
        return item
      })

      return { ...state, cart }
    }
    case DECREMENT_ITEM: {
      const { inventoryId } = action
      return {
        ...state,
        cart: state.cart.map(item => (item.inventoryId === inventoryId ? { ...item, quantity: item.quantity - 1 } : item)),
      }
    }
    case SELECT_INVENTORY_ITEM:
    case UPDATE_PRICES: {
      return {
        ...state,
        cart: state.cart.map(item => {
          if (!Array.isArray(action.pricingDetails)) {
            return state
          }

          const pricingDetail = action.pricingDetails.find(detail => detail.inventoryId === item.inventoryId) || {}
          const availabilityId = action.inventoryItems?.find(inv => inv.inventoryId === item.inventoryId)?.availabilityId
          return {
            ...item,
            ...pricingDetail,
            ...(availabilityId ? { availabilityId } : undefined),
          }
        }),
      }
    }
    default:
      return state
  }
}

function isInSameInventoryGroup(inventoryTypeA: InventoryType, inventoryTypeB: InventoryType | undefined) {
  if (!inventoryTypeB) {
    return false
  }
  const guestListTypes = [GUESTLIST_TYPE, GUESTLIST_FREE_TYPE]
  return inventoryTypeA === inventoryTypeB || (guestListTypes.includes(inventoryTypeA) && guestListTypes.includes(inventoryTypeB))
}

export default inventoryCart
